<template>
  <div>
    <bulk-patient-recording></bulk-patient-recording>
  </div>
</template>

<script>
import {
  default as BulkPatientRecording
} from "@/components/widgets/BulkPatientRecording";

export default {
  name: "BulkRecordPatients",
  computed: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {},
  components: {
    BulkPatientRecording
  }
};

</script>

<style type="text/css"></style>

