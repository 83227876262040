<template>
  <div>
    <b-card header-tag="header" footer-tag="footer" style="height: 750px;">
      <b-card style="margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Toplu Hasta Kayıt İşlemi
        </b-card-header>
        <b-row v-if="user.permissions_result && user.permissions_result.indexOf('administrator') !== -1">
          <b-col cols="12">
            <b-row>
              <b-col cols="6">
                <b-form-select :plain="true" v-model="d_hospitalGroup" @change="f_changeHospitalGroup()" required>
                  <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
                </b-form-select>
              </b-col>
              <b-col cols="6">
                <b-form-select :plain="true" v-model="d_hospitalId" required>
                  <!-- <b-form-select :plain="true" v-model="d_hospitalId" @change="f_changeHospitalId()" required> -->
                  <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-select :plain="true" v-model="d_listType" required>
              <option value="identity_no"> TC Kimlik Numarası </option>
              <option value="hims_patient_no"> HBYS Hasta Numarası </option>
            </b-form-select>
          </b-col>
          <b-col cols="10">
            <b-form-input id="search_patient" :placeholder="d_listType === 'hims_patient_no' ? 'HBYS hasta numaralarını araya virgül koyarak giriniz' : 'TC kimlik numaralarını araya virgül koyarak giriniz'" type="text" v-model="d_bulkPatientNo"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="warning" size="md" style="width: 100%;" @click="f_recordBulkPatients()"><i class="fa fa-cloud-download"></i> Hastaları Kaydet</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PatientService from '@/services/patient';
import HospitalService from '@/services/hospital';
import vSelect from 'vue-select';
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "BulkPatientRecording",
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      lang: "lang"
    })
  },
  data () {
    return {
      d_listType: 'hims_patient_no',
      d_bulkPatientNo: '',
      d_hospitalGroup: '',
      d_hospitalId: '',
      d_hospitalGroupList: [],
      d_hospitalList: []
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_hospitalGroup = this.user.hospital_group;
    this.d_hospitalId = this.user.hospital_id;
    this.f_getHospitalGroup();
  },
  mounted: function () {},
  methods: {
    f_recordBulkPatients: function () {
      if (this.d_hospitalId !== '' && this.d_hospitalGroup !== '' && this.patient_str_list !== '' && this.d_listType !== '') {
        let modal_data = { 'type': 'confirm', 'text': 'Bu işlemi gerçekleştirmek istediğinize emin misiniz?', 'centered': true, 'title': 'Toplu hasta kayıt işlemi hakkında', 'confirm': false, 'function_name': 'f_recordBulkPatientsConfirmed', 'arguments': [] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        alert('Bilgileri eksiksiz doldurunuz');
      }
    },
    f_recordBulkPatientsConfirmed: function () {
      if (this.d_hospitalId !== '' && this.d_hospitalGroup !== '' && this.patient_str_list !== '' && this.d_listType !== '') {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta Kayıt Operasyonu Başladı' } });
        let data = {};
        data.hospital_group = this.d_hospitalGroup;
        data.hospital_id = this.d_hospitalId;
        data.patient_str_list = this.d_bulkPatientNo;
        data.list_type = this.d_listType;
        PatientService.bulk_record_patients_with_hims_no_or_identity_no(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.12656') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Hasta Kayıt İşlemi Tamamlanamadı', 'centered': true, 'title': this.$t('wdm16.12656') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        alert('Bilgileri eksiksiz doldurunuz');
      }
    },
    f_changeHospitalGroup: function () {
      for (let i in this.d_hospitalGroupList) {
        if (this.d_hospitalGroupList[i].value === this.d_hospitalGroup) {
          this.d_hospitalList = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
          this.$forceUpdate();
          break;
        }
      }
    },
    f_getHospitalGroup: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = JSON.parse(JSON.stringify(resp.data.result));
            this.f_changeHospitalGroup();
          }
        });
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_recordBulkPatientsConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_recordBulkPatientsConfirmed();
          }
        }
      }
    }
  },
  components: {
    Modal,
    vSelect
  }
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>

